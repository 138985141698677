//
// Forms
//

// Include Form Components
@import "forms/base";

.mat-form-field-underline {
    display: none !important;
}

.mat-form-field-wrapper {
    padding-bottom: 0px;
}
.mat-radio-label-content {
    width: 100% !important;
}

.mat-checkbox-frame {
    border-width: 1px;
    border-color: #e3e3e3;
}
.mat-form-field {
    &.mat-focused {
        .mat-form-field-label {
            color: #989898;
        }
    }
    .mat-form-field-label {
        color: #989898;
    }
    &.mat-form-field-invalid {
        .mat-form-field-flex {
            background-color: #ffcece4a;
            border-color: #e13939;
            .mat-form-field-label {
                color: #989898;
            }
        }
    }
    &.mat-form-field-valid {
        .mat-form-field-flex {
            background-color: #eefee2;
            border-color: rgba(94, 207, 131, 0.53);
            .mat-form-field-label {
                color: #989898;
            }
        }
    }
}
.mat-form-field-appearance-fill .mat-form-field-flex {
    border-radius: 4px !important;
    background-color: white;
    border: 1px solid#77777769;
}

.text-muter-2 {
    color: #989898;
}
