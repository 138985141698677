//
// Base
//

// Body
body {
	background: $page-bg;
}

.image-input-wrapper {
	background-size: contain;
}

// Desktop Mode
@include media-breakpoint-up(lg) {
	// Wrapper
	.wrapper {
		// Fixed Header
		.header-fixed & {
			padding-top: get($header-config, desktop, fixed, height);
		}

		// Fixed Content Head
		.header-fixed.subheader-fixed.subheader-enabled & {
			padding-top: get($header-config, desktop, fixed, height) + get($subheader-config, fixed, height);
		}

		// Fixed Aside
		.aside-fixed & {
			padding-left: get($aside-config, base, width);
		}

		// Minimize Aside
		.aside-fixed.aside-minimize:not(.aside-minimize-hover) & {
			padding-left: get($aside-config, base, minimized-width);
		}
	}

	// Container
	.container,
	.container-fluid {
		padding: 0 get($page-padding, desktop);
	}
}

// Tablet & Mobile Modes
@include media-breakpoint-down(md) {
	// Wrapper
	.wrapper {
		// Fixed Header
		.header-mobile-fixed & {
			padding-top: get($header-config, tablet-and-mobile, fixed, height);
		}

		// Fixed Subheader
		.header-mobile-fixed.subheader-mobile-fixed.subheader-enabled & {
			padding-top: get($header-config, tablet-and-mobile, fixed, height) +
				get($subheader-config, fixed, height-mobile);
		}
	}

	// Container
	.container,
	.container-fluid {
		max-width: none;
		padding: 0 get($page-padding, tablet-and-mobile);
	}
}

.calendar-tab {
	background-color: #e4e4e4;
	border-radius: 4px;
	color: #989898;
	margin-right: 1rem;

	&:last-child {
		margin-right: 0px !important;
	}

	&.mat-button-toggle-checked {
		background-color: $success;
		color: $success-inverse;
	}

	.mat-button-toggle-label-content {
		padding: 5px 12px !important;
		line-height: 20px;
	}
}

.gender-tab {
	background-color: #e4e4e4 !important;;
	border-radius: 4px !important;;
	color: #989898 !important;;
	margin-right: 1rem !important;;
	height: 2rem !important;;

	.mat-button-toggle-label-content {
		line-height: 2rem !important;
	}

	.mat-pseudo-checkbox {
		display: none !important;
	}

	&:last-child {
		margin-right: 0px !important;
	}

	&.mat-button-toggle-checked {
		background-color: $success !important;
		color: $success-inverse !important;
	}
}

.app-suffix {
	font-size: 1.6rem;
	transform: translateY(-0.3rem);
}
