/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700|Roboto:300,400,500,600,700");
// Global vendors
@import "../node_modules/bootstrap/dist/css/bootstrap.css";
// => Material
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import "../node_modules/@angular/material/prebuilt-themes/azure-blue.css";
@import "../node_modules/@ng-select/ng-select/themes/default.theme.css";

@import "../node_modules/highlight.js/styles/googlecode.css";
@import "../node_modules/perfect-scrollbar/css/perfect-scrollbar.css";

// Global fonts
@import "../node_modules/socicon/css/socicon.css";
@import "../node_modules/@fortawesome/fontawesome-free/css/all.min.css";
@import "./assets/plugins/flaticon/flaticon.css";
@import "./assets/plugins/flaticon2/flaticon.css";
@import "./assets/plugins/keenthemes-icons/font/ki.css";

// Metronic styles (replace these path when using RTL css below)
@import "./assets/sass/style.angular.scss";

// For RTL, check this documentation https://keenthemes.com/metronic/?page=docs&section=angular-rtl
// @import "./assets/sass/style.angular.rtl.css";

// Default Layout themes
@import "./assets/sass/themes/layout/header/base/light.scss";
@import "./assets/sass/themes/layout/header/menu/light.scss";
@import "./assets/sass/themes/layout/brand/dark.scss";
@import "./assets/sass/themes/layout/aside/dark.scss";

// Header themes
// .header-base-light {
//     @import "./assets/sass/themes/layout/header/base/light.scss";
// }

// .header-base-dark {
//     @import "./assets/sass/themes/layout/header/base/dark.scss";
// }

// Header Menu themes
// .header-menu-light {
//     @import "./assets/sass/themes/layout/header/menu/light.scss";
// }
// .header-menu-dark {
//     @import "./assets/sass/themes/layout/header/menu/dark.scss";
// }

// Brand themes
.brand-dark {
  @import "./assets/sass/themes/layout/brand/dark.scss";
}

.brand-light {
  @import "./assets/sass/themes/layout/brand/light.scss";
}

// Aside themes
// .aside-dark {
//   @import "./assets/sass/themes/layout/aside/dark.scss";
// }

// .aside-light {
//   @import "./assets/sass/themes/layout/aside/light.scss";
// }

// hide bootstrap dropdown arrow globally
.dropdown-toggle::after {
  display: none !important;
}

.angular-bootstrap-table {
  overflow-x: auto;
}
.angular-bootstrap-table {
  th {
    outline: none;

    &.sortable {
      .svg-icon-sort {
        opacity: 0;
      }

      &:hover {
        cursor: pointer;

        .svg-icon-sort {
          opacity: 1;
        }
      }
    }
  }

  .table.table-head-custom thead th.sortable-active {
    color: #3699ff !important;
  }
}

.cursor-default {
  cursor: default !important;
}

.progress-modal {
  height: 3px;
  width: 100%;
}

.settings-menu {
  @media screen and (max-width: 980px) {
    width: 40px !important;

    .profile-card {
      border: 1px solid lightgray;
    }

    .navi-item .navi-link {
      padding: 2px !important;
      margin: 5px !important;
      .navi-text {
        display: none !important;
      }
    }

    .logout-link {
      span {
        display: none;
      }
    }
  }
}
.card-control {
  width: 100%;
}
.card-control iframe,
#iframesSubmit iframe {
  width: 100% !important;
}

.pac-container {
  z-index: 100000 !important;
}

.timepicker-overlay {
  z-index: 1200 !important;
}

.superaide-selected {
  .superaide-card {
    border-color: gray !important;
  }
}
.timepicker-backdrop-overlay {
  z-index: 1065 !important;
}

img.img-circle {
  border-radius: 50%;
}

.profile-card {
  min-height: 68vh;

  .logout-link {
      position: absolute;
      bottom: 1.2rem;
      padding: 0.75rem 1.2rem;

      span {
          padding: 1rem;
      }
  }
}

.navi.navi-hover .navi-item {
  border-bottom: 0.5px solid #e6e6e6;

  .navi-link {
      background-color: #fff;
      padding: 0.75rem 1rem;

      .navi-text {
          font-weight: 600;
          font-size: 1.2rem;
          color: #333333;
          padding-left: 5px;
      }

      &.active {
          .navi-text {
              color: $success;
          }
      }

      &:hover {
          .navi-text {
              color: $success;
          }
      }
  }
}


.profile-card {
  min-height: 68vh;

  .logout-link {
      position: absolute;
      bottom: 1.2rem;
      padding: 0.75rem 1.2rem;

      span {
          padding: 1rem;
      }
  }
}

.navi.navi-hover .navi-item {
  border-bottom: 0.5px solid #e6e6e6;

  .navi-link {
      background-color: #fff;
      padding: 0.75rem 1rem;

      .navi-text {
          font-weight: 600;
          font-size: 1.2rem;
          color: #333333;
          padding-left: 5px;
      }

      &.active {
          .navi-text {
              color: $success;
          }
      }

      &:hover {
          .navi-text {
              color: $success;
          }
      }
  }
}

.auth-logo-image {
  max-width: 100px;
}

.cdk-overlay-container{
  z-index: 1051;
}

.mat-mdc-form-field {
  width: 100%;
}

.mat-mdc-text-field-wrapper {
  border: 1px solid #ced4da !important;
  border-radius: 0.375rem !important;
  padding: 0.375rem 0.75rem !important;
  background-color: #fff !important;
}

.mat-mdc-form-field-focus-overlay {
  display: none !important;
}

.mat-mdc-form-field:hover .mat-mdc-text-field-wrapper {
  border-color: #86b7fe !important;
}

.mat-mdc-form-field.mat-focused .mat-mdc-text-field-wrapper {
  border-color: #80bdff !important;
  box-shadow: 0 0 0 0.25rem rgba(128, 189, 255, 0.25);
}
