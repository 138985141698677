// //
// // To make future updates easier consider overriding the global variables from _variables.bootstrap.scss and _variables.custom.scss for current demo in this file.
// // Note that this file is included first and variables defined in _variables.bootstrap.scss and _variables.custom.scss
// // are not accessible in this file but you can override any global variable as shown below:
// //

// // Theme colors
// // Override primary color variants
$primary:       									#01416F; // Bootstrap variable
$primary-hover:    									#187DE4; // Custom variable
$primary-light:    									#E1F0FF; // Custom variable
$primary-inverse:  									#FFFFFF; // Custom variable


//---------------------------
//
// To make future updates easier consider overriding the global variables from _variables.bootstrap.scss and _variables.custom.scss for current demo in this file.
// Note that this file is included first and variables defined in _variables.bootstrap.scss and _variables.custom.scss
// are not accessible in this file but you can override any global variable as shown below:
//

// Theme colors
// Override primary color variants
// $primary:       									#C83E4D; // Bootstrap variable
// $primary-hover:    									#dc6270; // Custom variable
// $primary-light:    									#f0b6b6; // Custom variable
// $primary-inverse:  									#FFFFFF; // Custom variable

// // Success colors
// $success:       									#296bf0 !default; // Bootstrap variable
// $success-hover:    									#527cef !default; // Custom variable
// $success-light:    									#8e90dd3b !default; // Custom variable
// $success-inverse:  									#ffffff !default; // Custom variable